<template>
    <div>
        <v-card
            class="mt-3 mx-2"
            color="primary"
            dark>
            
            <div class="text-center text-h5 pt-3">יום {{ hebDay }} {{ hebDate }}</div>


            <v-card-actions class="mx-2 mt-4 pb-4">

                <v-chip 
                    class="mx-auto"
                    color="white" 
                    text-color="primary"
                    to="/tehilimDay">
                    <v-icon left color="primary">
                    mdi-calendar-today
                    </v-icon>
                    תהילים יומי
                </v-chip>

                <v-chip 
                    class="mx-auto"
                    color="white" 
                    text-color="primary"
                    to="/tehilim">
                    <v-icon left color="primary">
                    mdi-file-document-multiple-outline
                    </v-icon>
                    ספר תהילים
                </v-chip>

            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import hebTehilim from '@/scripts/heb-tehilim.js'

export default {
    data() {
        return {
            hebDay: '',
            hebDate: ''
        }
    },
    methods: {
        initData() {
            let year = (new Date()).getFullYear()
            let month = (new Date()).getMonth() + 1
            let day = (new Date()).getDate()
            fetch(`https://www.hebcal.com/converter?cfg=json&gy=${year}&gm=${month}&gd=${day}&g2h=1`)
                .then(res => res.clone().json())
                .then(res => {
                    this.hebDate = res.hebrew
                })
                .catch(() => {
                    
                })
        }
    },
    created() {
        this.hebDay = hebTehilim.days[(new Date()).getDay()]
        this.initData()
    }
}
</script>